@import "const.scss";

* {
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0.5rem;
	color: $color_default;
}

.ant-menu {
	color: $sider_default_color;
	border-right-width: 0;

	.ant-menu-submenu-selected {
		color: $sider_submenu_color;
	}

	.ant-menu-item-selected {
		background-color: rgba(0, 0, 0, 0) !important;
		color: $sider_primary_color;

		> a {
			color: $sider_primary_color !important;

			.icon {
				opacity: 1 !important;
			}
		}
	}

	.ant-menu-item {
		> a {
			color: $sider_default_color;

			&:hover {
				color: $sider_primary_color !important;
			}
		}

		&:after {
			content: none !important;
		}
	}

	.ant-menu-item-active,
	.ant-menu-submenu-active,
	.ant-menu-submenu-title:hover,
	.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
		color: $color_primary;
	}

	.ant-menu-submenu-title,
	.ant-menu-item {
		margin: 0 !important;
		padding-right: 0;

		> *:not(i):first-child {
			display: flex;
			align-items: center;
			height: 100%;

			.icon {
				margin-left: -24px;
				margin-right: 16px;
				width: 28px;
				text-align: center;
				font-size: 14px;
				opacity: 0.6;

				&::before {
					display: inline-block;
					width: 24px;
				}
			}

			span {
				padding-right: 18px;
				line-height: 1.3;
				white-space: normal;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		&:hover {
			color: $color_primary;

			.ant-menu-submenu-arrow {
				&:after,
				&:before {
					background: $bg_color_primary;
				}
			}
		}

		&:active {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}

	.ant-menu-submenu-arrow {
		right: 6px !important;

		&:after,
		&:before {
			background: rgba(0, 0, 0, 0.3);
			background-image: none !important;
		}
	}

	.ant-menu-item-divider {
		margin: 12px 0;
		background-color: rgba(128, 128, 128, 0.6);
	}

	&.ant-menu-root {
		padding: 12px 0;

		> .ant-menu-submenu {
			> .ant-menu-submenu-title {
				margin-left: -4px !important;
				margin-top: 16px !important;
				height: auto;
				line-height: normal;
				text-transform: uppercase;
				font-weight: 500;
				width: 100%;
				color: $sider_submenu_color;
				font-size: 11px;

				> span {
					height: 36px;
					line-height: 36px;
				}
			}

			> .ant-menu {
				background-color: $sider_bg_color;
			}
		}

		> .ant-menu-item {
			> *:not(i):first-child {
				> .icon {
					margin-left: -4px;
				}
			}
		}
	}
}

.ant-input-affix-wrapper {
	padding-top: 6px;
	padding-bottom: 6px;
	max-height: 36px;

	.ant-input-prefix {
		margin-right: 8px;

		i {
			opacity: 0.5;
		}
	}

	&:hover {
		border-color: $border_color_primary_light;
	}

	&.ant-input-affix-wrapper-focused {
		border-color: $border_color_primary;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	}
}

.ant-input-group-wrapper:not(.ant-input-search) {
	.ant-input-group-addon {
		.ant-btn {
			margin: -1px -11px;
			border-color: transparent !important;
			background-color: transparent !important;
		}
	}
}

.ant-picker {
	padding-top: 6px;
	padding-bottom: 6px;
	min-width: 130px;

	&:hover {
		border-color: $border_color_primary_light;
	}

	&.ant-picker-focused {
		border-color: $border_color_primary_light;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	}
}

.ant-input {
	padding-top: 6px;
	padding-bottom: 6px;
	color: $color_default;

	&:hover {
		border-color: $border_color_primary_light !important;
	}

	&:focus {
		border-color: $border_color_primary !important;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	}
}

.ant-btn {
	padding-left: 1rem;
	padding-right: 1rem;
	height: 36px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: transparent;

	&:hover {
		background-color: transparent;
		border-color: $border_color_primary_light;
		color: $color_primary;
	}

	&:focus {
		border-color: $border_color_primary;
		color: $color_primary;
	}

	i {
		& + * {
			margin-left: 0.5rem;
		}
	}

	&.ant-btn-primary {
		background-color: $bg_color_primary;
		border-color: $border_color_primary;

		> a {
			color: #fff;
		}

		&:hover {
			border-color: $bg_color_primary_light;
			background-color: $bg_color_primary_light;
			color: #fff;
		}

		&:focus {
			border-color: $bg_color_primary;
			background-color: $bg_color_primary;
			color: #fff;
		}
	}

	&.ant-btn-link {
		color: $color_primary;
	}

	&.ant-btn-icon-only {
		width: 36px;
		height: 36px;
		line-height: 0.9;
		padding: 0;
		font-size: 16px;
		border-radius: 2px;
	}

	&.ant-btn-background-ghost {
		&.ant-btn-primary {
			color: $color_primary;

			&:hover {
				border-color: $bg_color_primary_light;
			}

			&:focus {
				border-color: $bg_color_primary;
			}
		}
	}
}

.ant-select {
	color: $color_default;

	.ant-select-clear {
		border-radius: 50%;
	}

	.ant-select-selection-search {
		.ant-select-selection-search-input {
			height: 34px !important;
		}
	}

	.ant-select-selector {
		height: 36px !important;

		&:not(.ant-select-disabled):hover {
			border-color: $border_color_primary_light;
		}

		.ant-select-selection-item,
		.ant-select-selection-placeholder {
			line-height: 34px;
		}

		.ant-select-selection-overflow-item {
			.ant-select-selection-item {
				line-height: 22px;
			}
		}
	}

	&.ant-select-focused {
		.ant-select-selector {
			border-color: $border_color_primary !important;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
		}
	}
}

.ant-select-dropdown {
	.ant-select-item {
		&.ant-select-item-option-selected {
			background-color: #eee;
		}
	}
}

.ant-dropdown {
	.ant-dropdown-menu-item {
		display: flex;
		min-height: 36px;
		align-items: center;

		> *:not(i):first-child {
			flex: 1;

			.icon {
				width: 24px;
				text-align: center;
			}

			.icon + span {
				margin-left: 12px;
				padding-right: 12px;
			}
		}
	}
}

.ant-pagination {
	.ant-pagination-item {
		margin-right: 4px;
		height: 36px;
		line-height: 34px;

		&:focus,
		&:hover {
			border-color: $border_color_primary;

			a {
				color: $color_primary;
			}
		}
	}

	.ant-pagination-item-active {
		border-color: $border_color_primary;

		a {
			color: $color_primary;
		}
	}

	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	.ant-pagination-next,
	.ant-pagination-prev {
		height: 36px;
		line-height: 34px;
	}
}

.ant-select-tree {
	.ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
		background-color: #eee;
	}
}

.ant-tree {
	.ant-tree-switcher-noop {
		> * {
			visibility: hidden;
		}
	}
}

.ant-row {
	.ant-col {
		.ant-row {
			margin-bottom: -0.5rem;
		}
	}
}

.ant-drawer {
	> .ant-drawer-content-wrapper {
		max-width: 100%;
		transform: translateX(20%) !important;
		opacity: 0;
		transition-property: all;
	}

	> .ant-drawer-mask {
		background-color: rgba(0, 0, 0, 0.2);
	}

	&.ant-drawer-open {
		> .ant-drawer-content-wrapper {
			transform: translateX(0) !important;
			opacity: 1;
		}
	}
}

.ant-modal-root {
	position: relative;
	z-index: 1700;

	.ant-modal-mask {
		background-color: rgba(0, 0, 0, 0.2);
	}
}

.ant-tabs {
	color: $color_default;

	.ant-tabs-bar {
		margin-bottom: 0.49rem;

		.ant-tabs-tab {
			&.ant-tabs-tab-active {
				color: $color_primary !important;
			}

			&:hover {
				color: $color_primary_light !important;
			}
		}
	}

	.ant-tabs-content {
		.ant-tabs-tabpane {
		}
	}
}

.ant-message-notice {
	.ant-message-notice-content {
		font-size: 1rem;
		color: #000;
	}
}
