$sider_width: 240px;

$color_default: #0d0d0d;
$color_primary: #214a6a;
$color_primary_light: #3263ad;
$color_secondary: #cc161c;
$color_secondary_light: #ee272e;
$color_status_success: #06c42c;
$color_status_fail: #cc161c;
$color_income: #F06862;
$color_commission: #F3AD63;
$color_profit: #86BEB6;

$border_color_primary: $color_primary;
$border_color_primary_light: $color_primary_light;
$border_color_secondary: $color_secondary;
$border_color_secondary_light: $color_secondary_light;

$bg_color_primary: $color_primary;
$bg_color_primary_light: $color_primary_light;
$bg_color_secondary: $color_secondary;
$bg_color_secondary_light: $color_secondary_light;

$sider_default_color: rgb(0, 0, 0);
$sider_primary_color: #f15f25;
$sider_submenu_color: rgb(122, 122, 122);
$sider_bg_color: #ffffff;
